body {
  margin: 0;
  font-family: "Tahoma", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #f4f6f8;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

svg {
  cursor: pointer;
  outline: none;
}

.drop-shadow {
  filter: drop-shadow(2px 2px 2px teal);
}

svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transform-origin: center top;
}
